import React, {useState} from 'react'
import { graphql } from "gatsby"
import { LOGIN_USER } from '../graphql/queries'
import { useMutation } from '@apollo/client'
import { navigate, Link } from 'gatsby'
import ContactBackground from '../images/contact-background.png'
import Form from 'components/form'
import SEO from 'components/seo'

export const query = graphql`
    query {
        siteFields {
            form(id: "Zm9ybToxOTk1") {
                ...FormFieldsFragment
            }
        }
    }
`

export default ({ data }) => {
    const {
        form,
    } = data.siteFields

    const [loginUser] = useMutation(LOGIN_USER)
    const [error, setError] = useState(null)

    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = _data => {
        if(!_data['Email address']){
            return handleError(`Please enter an email address.`)
        }

        if(!_data['Password']){
            return handleError(`Please enter a password.`)
        }

        setIsLoading(true)

        loginUser({
            variables: {
                username: _data['Email address'],
                password: _data['Password'],
            },
        }).then(result => {
            setIsLoading(false)

            const { user } = result.data.login

            window.localStorage.setItem("user", JSON.stringify(user))

            navigate('/portal',{
                state: {
                    userId: user.id,
                },
            })
        }).catch(() => {
            setIsLoading(false)
            handleError(`Those details don't appear to be correct.`)
        })
    }

    const handleError = error => {
        setError(error)

        setTimeout(() => {
            setError(null)
        },2000)
    }

    return (
        <div className="login-page">
            <SEO title="Log in" />
            <img className="bg" src={ContactBackground} alt="Log in" />
            <div className="container">
                <h1>
                    Log in to your FLAIM Portal
                </h1>
                <div className="wrapper">
                    <Form 
                        form={form}
                        handleSubmit={handleSubmit}
                        isLoading={isLoading}
                        error={error}
                    />
                    <div className="register-product">
                        <span>
                            Need to register a product?
                        </span>
                        <Link to={'/register'}>
                            Register now
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}